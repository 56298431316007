<script setup>
    import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';
    import { ref, reactive, onUnmounted, onMounted, computed } from 'vue';
    
    const consequenceAreas = reactive([]);
    const areaObjectsAdded = ref(false);
    const conseqAreaIsSelected = ref(false);

    const showProbabilityValue = ref(true);
    const showProbabilityText = ref(false);
    const showProbabilityPercent = ref(false);
    const showConsequenceText = ref(false);

    const emits = defineEmits([
        "MatrixSize", 
        "ShowProbabilityValue",
        "ShowProbabilityText",
        "ShowProbabilityPercent",
        "ShowConsequenceText", 
        "ConsequenceArea"
    ]);

    const props = defineProps({
        "Source": String,
        "TooManyIDs": { type: Boolean, default: false }
    });
    
    const sourceIsRisk = computed(() => {
        return props.Source === 'Risk';
    });

    const local_dsConsequenceAreas = getOrCreateDataObject({
        id: 'dsConsequenceAreas',
        viewName: 'atbv_RiskManager_ConsequenceAreas',
        maxRecords: -1,
        loadRecents: false,
        distinctRows: true,
        fields:
            [
                {name: "NameT", type: "string" },
                {name: "ID", type: "int"}
            ]
    });

    local_dsConsequenceAreas.on('DataLoaded', (data) => { 
        data.forEach(
            area => consequenceAreas.push({id: area.item.ID, name: area.item.NameT})
        )
        areaObjectsAdded.value = true;
    });
    
    onUnmounted(() => {
        deleteDataObject(local_dsConsequenceAreas.id, local_dsConsequenceAreas.appId);
    })

    onMounted(() => {
        local_dsConsequenceAreas.load();
        emits("MatrixSize", "table-matrix-md");
        emits("ShowProbabilityValue", showProbabilityValue.value);
        emits("ConsequenceArea", { id: "null", name: "Overall" });
    });

    const setDropdownElementActive = (element, activeClass) => {
        document.getElementsByClassName(activeClass)[0].classList.remove("active");
        element.srcElement.classList.add("active")
    }

    const setCheckboxEmits = (element) => {
        const el = element.target;
        if(el.checked) {
            emits(el.value, true);
        } else {
            emits(el.value, false);
        }
    }

    const setConsequenceArea = (element) => {
        setDropdownElementActive(element, 'consequenceAreas active');

        const conseqArea_ID = element.target.attributes.data_consequencearea_id.value;
        const conseqArea_Name = element.target.attributes.data_consequencearea_name.value;

        if(conseqArea_ID !== 'null') {
            conseqAreaIsSelected.value = true;
        } else {
            conseqAreaIsSelected.value = false; 
            showConsequenceText.value = false;
            emits("ShowConsequenceText", false);
        }

        emits("ConsequenceArea", { id: conseqArea_ID, name: conseqArea_Name });
    }
</script>

<template>
    <div class="row">
        <div class="col-12 mt-1">
            <div class="d-flex mt-0 mb-1" style="width:100%">
                <!-- MatrixSize -->
                <div class="dropdown">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        id="matrixSizeDD" 
                        data-bs-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-arrows-fullscreen me-1" aria-hidden="true"></i> {{ $t('Matrix Size') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD">
                        <button class="dropdown-item matrix-size" @click="(e) => { $emit('MatrixSize', 'table-matrix-sm'); setDropdownElementActive(e, 'matrix-size active') }">{{ $t('Small') }}</button>
                        <button class="dropdown-item matrix-size active" @click="(e) => { $emit('MatrixSize', 'table-matrix-md'); setDropdownElementActive(e, 'matrix-size active') }">{{ $t('Medium') }}</button>
                        <button class="dropdown-item matrix-size" @click="(e) => { $emit('MatrixSize', 'table-matrix-lg'); setDropdownElementActive(e, 'matrix-size active') }">{{ $t('Large') }}</button>
                    </div>
                </div>

                <!-- ConsequenceArea's -->
                <div class="dropdown mx-2" v-if="sourceIsRisk">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        id="matrixConsequenceDD" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;" 
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-eye" aria-hidden="true"></i> {{ $t('Consequence Area') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixConsequenceDD">
                        <button class="dropdown-item consequenceAreas active" @click="(e) => { setConsequenceArea(e); }" data_consequencearea_id="null" data_consequencearea_name="Overall">{{ $t('Overall') }}</button>
                        <button v-if="areaObjectsAdded" v-for="(areaObj) in consequenceAreas" class="dropdown-item consequenceAreas" @click="(e) => { setConsequenceArea(e); }" :data_consequencearea_id="areaObj.id" :data_consequencearea_name="areaObj.name">{{ $t(areaObj.name) }}</button>
                    </div>
                </div>

                <!-- Options -->
                <div class="dropdown" v-if="sourceIsRisk">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-auto-close="outside" 
                        data-bs-toggle="dropdown" 
                        id="matrixOptionsDD" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-gear-fill" aria-hidden="true"></i> {{ $t('Options') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD" style="width:275px;">
                        <h1 class="dropdown-header">{{ $t('Probability Label') }}</h1>
                        <div class="form-check ms-2">
                            <input class="form-check-input" type="checkbox" value="ShowProbabilityValue" v-model="showProbabilityValue" @change="(e) => { setCheckboxEmits(e) }" id="showProbabilityValue"> 
                            <label class="form-check-label" :title="$t('Show probability value')" for="showProbabilityValue">
                                {{ $t('Show probability value') }}
                            </label>
                        </div>
                        <div class="form-check ms-2">
                            <input class="form-check-input" type="checkbox" value="ShowProbabilityText" v-model="showProbabilityText" @change="(e) => { setCheckboxEmits(e) }" id="showProbabilityText">
                            <label class="form-check-label" :title="$t('Show probability text')" for="showProbabilityText">
                                 {{ $t('Show probability text') }}
                            </label>
                        </div>
                        <div class="form-check ms-2">
                            <input class="form-check-input" type="checkbox" value="ShowProbabilityPercent" v-model="showProbabilityPercent" @change="(e) => { setCheckboxEmits(e) }" id="showProbabilityPercent">
                            <label class="form-check-label" :title="$t('Show probability percent rage')" for="showProbabilityPercent">
                                {{ $t('Show probability percent range') }}
                            </label>
                        </div>

                        <div class="dropdown-divider" />
                        
                        <h1 class="dropdown-header">{{ $t('Consequence Label') }}</h1>
                        <div class="form-check ms-2">
                            <input :disabled="!conseqAreaIsSelected" class="form-check-input" type="checkbox" value="ShowConsequenceText" v-model="showConsequenceText" @change="(e) => { setCheckboxEmits(e) }" id="showConsequenceText">
                            <label class="form-check-label" :title="$t('Show consequence text. Only possible when a consequence area is selected.')" for="showConsequenceText">
                                {{ $t('Show consequence text') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>